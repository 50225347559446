import {
  BookingModel,
  BookingSearchModel,
  BookingsToChangeEnum,
  CampModel,
  CampsShoppingBasketModel,
  ResourceModel,
  UserModel,
} from '../../../common/src';

import * as backendApi from '../shared/backend-api';

export default class StaffManagerService {
  constructor() {}

  searchAllBookings(searchFilter: BookingSearchModel): Promise<BookingModel[]> {
    return backendApi.post('staff-manager/search-all-bookings', searchFilter);
  }

  findStaff(name: string, center?: number): Promise<UserModel[]> {
    return backendApi.get('staff-manager/find-staff', { name, center });
  }

  findResroucesForActivity(activityId: number, activityCenterId: number): Promise<ResourceModel[]> {
    return backendApi.get('staff-manager/find-resources-for-activity', { activityId, activityCenterId });
  }

  getBookingParticipants(bookingkey: string): Promise<UserModel[]> {
    return backendApi.get('staff-manager/get-booking-participants', { bookingkey });
  }

  addBookingParticipant(bookingkey: string, memberKey: string): Promise<UserModel[]> {
    return backendApi.post('staff-manager/add-booking-participant', { bookingkey, memberKey });
  }

  createStaffBooking(params: {
    selectedDate: string;
    selectedTime: string;
    selectedCenterId: number;
    selectedActivityId: number;
    selectedDuration: number;
    selectedResourceId: string | undefined; // "100br3"
    selectedInstructors: string[]; // ["100p405"]
    name: string | undefined;
    maxCapacity: number | undefined;
  }): Promise<any> {
    return backendApi.post('staff-manager/create-staff-booking', {
      selectedDate: params.selectedDate,
      selectedTime: params.selectedTime,
      selectedCenterId: params.selectedCenterId,
      selectedActivityId: params.selectedActivityId,
      selectedDuration: params.selectedDuration,
      selectedResourceId: params.selectedResourceId,
      selectedInstructors: params.selectedInstructors,
      name: params.name,
      maxCapacity: params.maxCapacity,
    });
  }

  updateStaffBooking(
    bookingId: number,
    bookingCenterId: number,
    startDateTime: string,
    duration: number,
    resource: string | undefined
  ): Promise<any> {
    return backendApi.post('staff-manager/update-staff-booking', {
      bookingId,
      bookingCenterId,
      startDateTime,
      duration,
      resource,
    });
  }

  deleteStaffBooking(
    bookingkey: string,
    cancelReason: string,
    toParticipants: boolean,
    toStaff: boolean,
    cancelEffect: BookingsToChangeEnum
  ): Promise<any> {
    return backendApi.post('staff-manager/delete-staff-booking', {
      bookingkey,
      cancelReason,
      toParticipants,
      toStaff,
      cancelEffect,
    });
  }
}
