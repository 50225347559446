import {
  ActivityModel,
  BookingDatesModel,
  BookingSearchModel,
  ParticipationModel,
  SeatModel,
  SubscriptionModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class BookingService {
  constructor() {}

  // TODO: userId and userCenterId will eventually be mandatory
  cancelBooking(
    participationCenterId: number,
    participationId: number,
    selectedUserId: number,
    selectedUserCenterId: number
  ): Promise<boolean> {
    return backendApi.post('booking/cancel-booking', { selectedUserId, selectedUserCenterId, participationCenterId, participationId });
  }

  bookClass(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingCenterId: number,
    bookingId: number,
    seatName?: string
  ): Promise<ParticipationModel> {
    return backendApi.post('booking/create-booking', { selectedUserId, selectedUserCenterId, bookingCenterId, bookingId, seatName });
  }

  attemptBookClass(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingCenterId: number,
    bookingId: number,
    seatName?: string
  ): Promise<ParticipationModel> {
    return backendApi.post('booking/attempt-create-booking', {
      selectedUserId,
      selectedUserCenterId,
      bookingCenterId,
      bookingId,
      seatName,
    });
  }

  getSeats(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingCenterId: number,
    activityId: number,
    bookingId: number,
    clearPreviousTentative: boolean
  ): Promise<SeatModel[]> {
    return backendApi.post('booking/get-seats', {
      selectedUserId,
      selectedUserCenterId,
      bookingCenterId,
      activityId,
      bookingId,
      clearPreviousTentative,
    });
  }

  // clear-previous-tentative-participations
  clearPreviousTentativeParticipations(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingCenterId: number,
    bookingId: number
  ): Promise<void> {
    return backendApi.post('booking/clear-previous-tentative-participations', {
      selectedUserId,
      selectedUserCenterId,
      bookingCenterId,
      bookingId,
    });
  }

  checkPrivilegeParticipations(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingCenterId: number,
    bookingId: number
  ): Promise<boolean> {
    return backendApi.post('booking/check-privilege-booking', { selectedUserId, selectedUserCenterId, bookingCenterId, bookingId });
  }

  getAvailableClipcardsForBooking(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingId: number,
    bookingCenterId: number
  ): Promise<[]> {
    return backendApi.post('booking/available-booking-clipcards', {
      selectedUserId,
      selectedUserCenterId,
      bookingId,
      bookingCenterId,
    });
  }

  getAvailableUserSubscriptionsForBooking(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingCenterId: number,
    bookingId: number
  ): Promise<SubscriptionModel[]> {
    return backendApi.post('booking/available-user-subscriptions-for-booking', {
      selectedUserId,
      selectedUserCenterId,
      bookingId,
      bookingCenterId,
    });
  }
}
