import {
  AccountOverviewConsolidatedInvoiceModel,
  AccountOverviewModel,
  AccountOverviewPayableDebtStatus,
  CompositeKeyModel,
  DocumentModel,
  InvoiceModel,
  PaymentModel,
  SaleDetailModel,
  SaleModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class InvoicesService {
  constructor() {}

  /**
   * Get payments from a certain day range
   * @param monthFrom Date which you want to pick payments from: YYYY-MM
   * @param dateTo Date which you want to pick payments to: YYYY-MM
   * @returns Promise<PaymentModel[]>
   */
  loadInvoices(monthFrom: string, monthTo: string, invoiceType: string): Promise<PaymentModel[]> {
    return backendApi.post('invoices/get-invoices', {
      monthFrom,
      monthTo,
      invoiceType,
    });
  }

  downloadInvoice(subId: number, centerId: number, id: number, deductionDate: string, invoiceType: string): Promise<DocumentModel> {
    return backendApi.post('invoices/download-invoice', {
      subId,
      centerId,
      id,
      deductionDate,
      invoiceType,
    });
  }

  loadAccountOverview(): Promise<AccountOverviewModel> {
    return backendApi.post('invoices/get-account-overview');
  }

  getSalesList(monthFrom: string, monthTo: string, selectedUserId: number, selectedUserCenterId: number): Promise<SaleModel[]> {
    return backendApi.post('invoices/get-saleslist', { monthFrom, monthTo, selectedUserId, selectedUserCenterId });
  }

  getSaleDetail(
    monthFrom: string,
    monthTo: string,
    invoiceId: number | undefined,
    invoiceCenterId: number | undefined,
    creditNoteId: number | undefined,
    creditNoteCenterId: number | undefined,
    selectedUserId: number,
    selectedUserCenterId: number
  ): Promise<SaleDetailModel> {
    return backendApi.post('invoices/get-sale-detail', {
      monthFrom,
      monthTo,
      invoiceId,
      invoiceCenterId,
      creditNoteId,
      creditNoteCenterId,
      selectedUserId,
      selectedUserCenterId,
    });
  }

  downloadSalesList(invoiceKey: CompositeKeyModel): Promise<DocumentModel> {
    return backendApi.post('invoices/download-sales-list', {
      invoiceKey,
    });
  }
}
