import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "data-cy": "straplines"
}
const _hoisted_2 = ["data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_html_textarea = _resolveComponent("html-textarea")!

  return (_ctx.numberOfItems)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (block, url, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: url + '-' + block.straplineContent.language,
            "data-cy": 'strapline-' + index,
            class: "bg-primary-500 flex items-center justify-center text-xs tracking-widest text-center px-4 py-3",
            style: _normalizeStyle({ 'background-color': block.backgroundColor })
          }, [
            _createVNode(_component_html_textarea, {
              value: block.straplineContent.value,
              "data-cy": "input"
            }, null, 8, ["value"])
          ], 12, _hoisted_2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}