import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({
  props: {
    value: String,
  },
  components: {},
})
export default class HtmlTextarea extends Vue {
  value!: string;

  mounted() {
    this.updateInnerHtml();
  }

  @Watch('value')
  onValueChange() {
    this.updateInnerHtml();
  }

  private updateInnerHtml() {
    this.$el.innerHTML = this.value;
  }
}
