import { Options, Vue } from 'vue-class-component';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Language } from '@/i18n';
import store from '@/store';
import { getLanguage } from '@/shared/utils';

@Options({
  props: {
    text: String,
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
})
export default class LanguageDropdown extends Vue {
  currentLanguage: Language | null = null;

  beforeMount(): void {
    const currentLanguage = getLanguage(localStorage.language);
    this.currentLanguage = currentLanguage;
  }

  changeLanguage(lang: string): void {
    this.$i18n.locale = lang;
    localStorage.language = lang;
    this.currentLanguage = getLanguage(lang);
    store.commit('currentLocale', this.currentLanguage);
  }
}
