import { MemberFriendInfosModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class BringAFriendService {
  constructor() {}

  getMemberFriendInfos(): Promise<MemberFriendInfosModel[]> {
    return backendApi.get('bring-a-friend/member-friend-infos');
  }

  validateFriendCode(
    refererId: number,
    refererCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSubId: number,
    friendCode: string
  ): Promise<MemberFriendInfosModel> {
    return backendApi.post('bring-a-friend/validate-friend-code', {
      refererId,
      refererCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSubId,
      friendCode,
    });
  }

  registerFriendCode(
    refererId: number,
    refererCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSubId: number,
    friendCode: string
  ): Promise<void> {
    return backendApi.post('bring-a-friend/register-friend-code', {
      refererId,
      refererCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSubId,
      friendCode,
    });
  }
}
