import { Options, Vue } from 'vue-class-component';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import { UrlContentObjArrayModel } from '@/../../common/src';
import HtmlTextarea from '@/components/ui/html-textarea.component.vue';
import store from '@/store';
import { sanitizeHtml } from '@/shared/utils';

@Options({
  components: {
    InformationCircleIcon,
    HtmlTextarea,
  },
  emits: [],
})
export default class ContentBlock extends Vue {
  items: UrlContentObjArrayModel = {};

  created() {
    this.items = store.state.config?.contentBlocks?.htmlContents as UrlContentObjArrayModel;
  }

  get visibleItems(): UrlContentObjArrayModel {
    const tempItems: UrlContentObjArrayModel = {};
    for (const key in this.items) {
      if (Object.prototype.hasOwnProperty.call(this.items, key)) {
        if (this.filterOutContentByUrl(key)) {
          tempItems[key] = sanitizeHtml(this.items[key]);
        }
      }
    }
    return tempItems;
  }

  get numberOfItems(): number {
    return Object.keys(this.visibleItems).length;
  }

  filterOutContentByUrl(url: string): boolean {
    let isVisible = false;
    url = url.trim();
    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }
    if (this.$route.fullPath.toLowerCase().includes(url.toLowerCase())) {
      isVisible = true;
    }
    return isVisible;
  }
}
