import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["dir"]
const _hoisted_2 = { class: "w-full max-w-7xl mx-auto px-4 lg:px-5" }
const _hoisted_3 = {
  class: "sticky top-[100vh]",
  "data-cy": "the-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_config_error = _resolveComponent("config-error")!
  const _component_config_loading = _resolveComponent("config-loading")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock("div", {
    class: "text-base text-gray-500 min-h-screen overflow-hidden",
    dir: _ctx.$store.state.currentLocale?.direction
  }, [
    (_ctx.$store.getters.configError)
      ? (_openBlock(), _createBlock(_component_config_error, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.$store.getters.config && !_ctx.$store.getters.configError)
      ? (_openBlock(), _createBlock(_component_config_loading, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.$store.getters.config && !_ctx.$store.getters.configError)
      ? (_openBlock(), _createBlock(_component_the_header, { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$store.getters.config && !_ctx.$store.getters.configError)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.$store.getters.config && !_ctx.$store.getters.configError)
        ? (_openBlock(), _createBlock(_component_the_footer, {
            key: 0,
            id: "theFooter"
          }))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}