import {
  ConfigWebUserModel,
  PaymentProvidersConfigModel,
  PaymentProvidersEnum,
} from "./config.model";
import {
  CenterModel,
  CompositeSubKeyModel,
  ProductGroupModel,
  TimeUnitModel,
  RelatedPersonModel,
  ShoppingBasketModel,
  PaymentOptionsModel,
} from "..";

export interface SubscriptionModel {
  id: number;
  centerId: number;
  freezes: FreezeModel[];
  groups: ProductGroupModel[];
  name: string;
  currency?: string;
  state?: string;
  subState?: string;
  startDate?: string;
  endDate?: string;
  productGlobalId?: string;

  description?: string;
  sellingPoints?: string[];

  subscriptionType: SubscriptionTypeModel;

  /** joining fees */
  creationPrice?: number;
  creationNormalPrice?: number;
  creationPriceText?: string;

  /** period price */
  periodPrice?: number;
  periodNormalPrice?: number;
  campaignPrice?: number;

  /** period text */
  periodPriceText?: string;
  campaignText?: string;

  periodLength?: number;
  periodUnit?: TimeUnitModel;
  mostPopular?: boolean;
  rank?: number;
  addOns?: AddOnModel[];

  canChangeSubscription?: boolean;
  canFreezeSubscription?: boolean;
  canTerminateSubscription?: boolean;

  availableSizes: number[];

  userHasIt?: boolean;
  availableFreeze?: AvailableFreezeModel;

  relative?: RelatedPersonModel;

  paymentOptions?: PaymentOptionsModel[];

  clearingHouseTypeRestriction?: SubscriptionClearingHouseTypeRestrictionModel;
  paymentAgreementKey?: CompositeSubKeyModel;
}

export interface ClipcardSubscriptionModel {
  id: number;
  center: number;
  name: string;
  basket: ShoppingBasketModel;
}

export type FreezeTypeModel = "UNCONDITIONAL" | "CONDITIONAL";
export interface FreezeModel {
  subId: number;
  center: number;
  id: number;
  type?: FreezeTypeModel;
  startDate?: string;
  endDate?: string;
  comment?: string;
}

export interface AvailableFreezeModel {
  subscriptionId?: number;
  subscriptionCenterId?: number;
  freezeStartProduct?: ProductModel;
  freezeMinimumDuration?: number;
  freezeMaximumDuration?: number;
  freezePeriodUnit?: string;
  freezePeriodPrice?: string;
  freezePeriodNumberOfUnits?: number;
  freezeMaximumDurationUnit?: string;
  freezeMinimumDurationUnit?: string;
  allowedFreezeCount?: number;
  allowedFreezeWithinPeriod?: number;
}
export interface FirstFreezePeriodModel {
  firstPossibleStartDate?: string;
  unit?: string;
  amount?: number;
}

export interface ProductModel {
  productId?: number;
  productCenterId?: number;
  name?: string;
  price?: number;
  ref?: string;
  barcodes?: object;
  type?: FreezeProductTypeModel;
  productGroups?: Array<ProductGroupModel>;
  totalVat?: number;
}

export type FreezeProductTypeModel =
  | "GOOD"
  | "SERVICE"
  | "CLIPCARD"
  | "SUBSCRIPTION";
export const FreezeProductTypeModel = {
  Good: "GOOD" as FreezeProductTypeModel,
  Service: "SERVICE" as FreezeProductTypeModel,
  Clipcard: "CLIPCARD" as FreezeProductTypeModel,
  Subscription: "SUBSCRIPTION" as FreezeProductTypeModel,
};

export type SubscriptionTypeModel =
  | "CASH"
  | "EFT"
  | "RECURRING_CLIPCARD"
  | "COURSE";

export type PersonPaymentTypeModel = "INVOICE" | "EFT" | "CREDIT_CARD";
export const PersonPaymentTypeModel = {
  Invoice: "INVOICE" as PersonPaymentTypeModel,
  Eft: "EFT" as PersonPaymentTypeModel,
  CreditCard: "CREDIT_CARD" as PersonPaymentTypeModel,
};

export interface PaymentAgreenentModel {
  compositeSubKey: CompositeSubKeyModel;
  display: string;
  changeButton: boolean;
  clearingHouseReference: string | undefined;
  cardOnFile: boolean | undefined;
  isDefault: boolean | undefined;
  paymentProvidersUpdateCC: PaymentProvidersEnum[];
  paymentProvidersUpdateDD: PaymentProvidersEnum[];
  paymentProvidersUpdateInvoice: PaymentProvidersEnum[];
  paymentType: PersonPaymentTypeModel | "OTHER";
  reference: string | undefined;
}

export interface PaymentAgreementLightModel {
  compositeSubKey: CompositeSubKeyModel;
  paymentType?: PersonPaymentTypeModel;
}
export interface AddOnModel {
  id: number;
  name?: string;
  description?: string;
  sellingPoints?: string[];
  price?: number;
  campaignPrice?: number;
  campaignText?: string;
  pricePeriodLength?: number;
  pricePeriodUnit?: TimeUnitModel;
  startDate?: string;
  endDate?: string;
  webname?: string;
}

export interface CampaignInformationForPersonModel {
  promoCode: string;
  id?: number;
  name?: string;
  description?: string;
}

export interface SelectedSubscriptionModel {
  selectedCenter: CenterModel;
  selectedSubscription: SubscriptionModel;
  currentSubscription?: SubscriptionModel;
  addOns: AddOnModel[];
  configForNewMember: ConfigWebUserModel; // TODO DEV-47286 Dont know about his one...
  configForExistingMember: ConfigWebUserModel; // TODO DEV-47286 Dont know about his one...
  priceDefinitionsIncludeSalesTax: boolean;
  startDate: string;
  minStartDate: string;
  campaignInformation?: CampaignInformationForPersonModel;
  paymentOptions?: PaymentOptionsModel[];
}

export const CONFIG_FOR_CHILD_SUBSCRIPTION: ConfigWebUserModel = {
  salutation: "not-used",
  country: "not-used",
  mobile: "not-used",
  address1: "not-used",
  address2: "not-used",
  zip: "not-used",
  city: "not-used",
  state: "not-used",
  ssn: "not-used",
  passport: "not-used",
  residentId: "not-used",
  nationalId: "not-used",
  preferredLanguage: "not-used",
  email: "not-used",
  password: "not-used",
  billing: "not-used",
  hideUndefinedGender: false,
  allowEmailNewsletters: false,
  allowThirdPartyOffers: false,
  disableEmailValidation: true,
  subscriptionStartDate: true,
  termsAndConditions: "",
  gender: "required",
  birthday: "required",
  firstName: "required",
  lastName: "required",
};

export interface SubscriptionContractSummaryModel {
  subscriptionId: number;
  center: CenterModel;
  promoCode?: string;
  currency: string;
  subscriptionExists: boolean;
  userHasActivePaymentAgreement: boolean;
  existingPaymentAgreementType?: PersonPaymentTypeModel;

  currentSubscription?: string;
  currentSubscriptionPaymentAgreementKey?: CompositeSubKeyModel;
  selectedSubscription: string;
  groups?: ProductGroupModel[];

  addOns: AddOnModel[];
  paymentPlan?: string;
  startDate: string;
  normalPeriodPrice?: number;
  firstDeductionDate?: string;

  newSubscriptionPrice?: {
    totalAmount: number;
    joiningFeePrice?: number;
    adminFeePrice?: number;
    initialPeriodPrice?: number;
    proRataPrice?: number;
    totalVat?: number;
  };
  changeSubscriptionPrice?: {
    changeAmount?: number;
    changeFee?: number;
    changeVat?: number;
    refundAmount?: number;
  };
  addAddonPrice?: {
    totalAmount: number;
    totalVat?: number;
  };

  subscriptionType?: SubscriptionTypeModel;

  shoppingBasketId?: string;

  paymentProvidersCCCreatePaymentAgreements: PaymentProvidersEnum[];
  paymentProvidersDDCreatePaymentAgreements: PaymentProvidersEnum[];
  paymentProvidersInvoiceCreatePaymentAgreements: PaymentProvidersEnum[];
  paymentProvidersJoiningFees: PaymentProvidersEnum[];
  paymentProvidersConfig: PaymentProvidersConfigModel;
  configAllowMultiplePaymentAgreements: boolean;
  hasConfCardOnFile: boolean;
  paymentOptions?: PaymentOptionsModel[];
  paymentMethodRestriction?: SubscriptionClearingHouseTypeRestrictionModel;
  productGlobalId?: string;

  hasToSelectPaymentOptionForRecurringEFTSub: boolean;
  newSubscriptionWillOverridesExistingPaymentAgreement: boolean;
  defaultPaymentMethod: "EFT" | "INVOICE" | "CREDIT_CARD" | "error" | undefined;
  preferredPayementMethod: "CREDIT_CARD" | "EFT" | undefined;
  canSelectPaymentOption: boolean;
  deductionDay: number | undefined;
  clearingHouseType: "EFT" | "INVOICE" | "CREDIT_CARD" | undefined;
}

export interface SuggestedTerminationDateModel {
  endDate: string;
  lastPayingDate: string;
  inBinding: boolean;
}

export interface SubscriptionStopInfoModel {
  lastTrainingDay?: string;
  lastPaidDay?: string;
}

export type PA_OPERATION_TYPE =
  | "CREATE_CC"
  | "CREATE_DD"
  | "CREATE_INVOICE"
  | "UPDATE_CC"
  | "UPDATE_DD"
  | "UPDATE_INVOICE";
export const PA_OPERATION_TYPE = {
  CREATE_CC: "CREATE_CC" as PA_OPERATION_TYPE,
  CREATE_DD: "CREATE_DD" as PA_OPERATION_TYPE,
  CREATE_INVOICE: "CREATE_INVOICE" as PA_OPERATION_TYPE,
  UPDATE_CC: "UPDATE_CC" as PA_OPERATION_TYPE,
  UPDATE_DD: "UPDATE_DD" as PA_OPERATION_TYPE,
  UPDATE_INVOICE: "UPDATE_INVOICE" as PA_OPERATION_TYPE,
};

export type SubscriptionClearingHouseTypeRestrictionModel =
  | "EFT"
  | "INVOICE"
  | "CREDIT_CARD";
export const SubscriptionClearingHouseTypeRestrictionModel = {
  Eft: "EFT" as SubscriptionClearingHouseTypeRestrictionModel,
  Invoice: "INVOICE" as SubscriptionClearingHouseTypeRestrictionModel,
  CreditCard: "CREDIT_CARD" as SubscriptionClearingHouseTypeRestrictionModel,
};

export interface DocumentCacheModel {
  content: string;
  contentType: string;
  filename: string;
  area: "QUALITOP" | "OTHER";
}
