import { App } from 'vue';

const tooltipDirective = (app: App<Element>) => {
  app.directive('tooltip', {
    mounted(el: HTMLElement, binding: any) {
      init(el, binding);
    },
    updated(el: HTMLElement, binding: any) {
      init(el, binding);
    },
  });
};

function init(el: HTMLElement, binding: any) {
  if (binding.value) {
    const position = binding.arg;
    const tooltipText = binding.value;
    el.setAttribute('position', position);
    el.setAttribute('tooltip', tooltipText);
  }
}

export default tooltipDirective;
