import axios, { AxiosResponse } from 'axios';
import { ErrorModel } from '../../../common/src';

export function post<T>(endpoint: string, postData?: any, getData?: any): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    axios
      .post(endpoint, postData, { params: getData })
      .then((response: AxiosResponse<T>) => {
        resolve(response.data);
      })
      .catch((error: ErrorModel) => {
        reject(error);
      });
  });
}

export function get<T>(endpoint: string, params?: any, headers?: any): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    axios
      .get(endpoint, { params, headers })
      .then((response: AxiosResponse<T>) => {
        resolve(response.data);
      })
      .catch((error: ErrorModel) => {
        reject(error);
      });
  });
}
