import { ApiGenderModel, CountryModel, FamilyModel, LocaleModel, PersonDetailModel, PersonModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';
export default class PersonService {
  constructor() {}

  getPersonDetail(): Promise<PersonDetailModel> {
    return backendApi.post('person/load-person-detail-for-profile');
  }

  openBraze(): Promise<string> {
    return backendApi.post('person/braze');
  }

  findPersonDetail(selectedUserId: number, selectedUserCenterId: number): Promise<PersonDetailModel> {
    return backendApi.post('person/find-person-details', { selectedUserId, selectedUserCenterId });
  }

  createPerson(centerId: number, password: string, person: PersonDetailModel): Promise<PersonDetailModel> {
    return backendApi.post('person/create-person', { centerId, password, person });
  }

  updateInformation(newInformation: PersonDetailModel): Promise<PersonDetailModel> {
    return backendApi.post('person/update-information', { newInformation });
  }

  updatePassword(currentPassword: string, newPassword: string): Promise<boolean> {
    return backendApi.post('person/update-password', { currentPassword, newPassword });
  }

  updateEmail(currentPassword: string, newEmail: string): Promise<boolean> {
    return backendApi.post('person/update-email', { currentPassword, newEmail });
  }

  getLocaleParameters(centerId: number, withSalutations: boolean, withLanguages: boolean): Promise<LocaleModel> {
    return backendApi.post('person/get-locale-parameters', { centerId, withSalutations, withLanguages });
  }

  getCitiesByZipCode(zipCode: string, country: string): Promise<string[]> {
    return backendApi.post('person/get-cities-by-zip-code', { zipCode, country });
  }

  sendEmailCode(email: string): Promise<void> {
    return backendApi.post('person/send-email-code', { email });
  }

  findPersonByEmail(email: string): Promise<boolean> {
    return backendApi.post('person/find-person-by-email', { email });
  }

  checkEmailCode(email: string, code: string): Promise<boolean> {
    return backendApi.post('person/check-email-code', { email, code });
  }

  getFamilyProfiles(): Promise<FamilyModel[]> {
    return backendApi.post('person/get-family-profiles');
  }

  createFamily(familyName: string | undefined): Promise<FamilyModel> {
    return backendApi.post('person/create-family', { familyName });
  }

  addChild(familyId: number, childFirstName: string, childLastName: string, childBirthDate: string, gender: ApiGenderModel): Promise<void> {
    return backendApi.post('person/add-child', { familyId, childFirstName, childLastName, childBirthDate, gender });
  }

  getCountriesList(): Promise<CountryModel[]> {
    return backendApi.post('person/get-countries-list', {});
  }

  editChild(
    familyId: number,
    familyMemberId: number,
    familyMemberCenterId: number,
    childFirstName: string,
    childLastName: string,
    childBirthDate: string,
    gender: ApiGenderModel
  ): Promise<void> {
    return backendApi.post('person/edit-child', {
      familyId,
      familyMemberId,
      familyMemberCenterId,
      childFirstName,
      childLastName,
      childBirthDate,
      gender,
    });
  }

  getPersonGDPRStatus(): Promise<boolean> {
    return backendApi.get('person/get-gdpr-status', {});
  }

  setPersonGDPRStatus(gdprStatus: string): Promise<boolean> {
    return backendApi.post('person/set-gdpr-status', { gdprStatus });
  }

  generateLeadForPerson(centerId: number, personDetail: PersonDetailModel): Promise<PersonModel> {
    return backendApi.post('person/create-lead', { centerId, personDetail });
  }
}
