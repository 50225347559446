import { CompositeKeyModel } from "..";
import { ActivityModel, BookingModel } from "./booking.model";
import {
  ShoppingBasketItemModel,
  ShoppingBasketModel,
} from "./shopping-basket.model";

export type WeekDaysModel =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";
export const WeekDaysModel = {
  Monday: "MONDAY" as WeekDaysModel,
  Tuesday: "TUESDAY" as WeekDaysModel,
  Wednesday: "WEDNESDAY" as WeekDaysModel,
  Thursday: "THURSDAY" as WeekDaysModel,
  Friday: "FRIDAY" as WeekDaysModel,
  Saturday: "SATURDAY" as WeekDaysModel,
  Sunday: "SUNDAY" as WeekDaysModel,
};
export const WeekDaysOrderModel = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export interface CourseTypeModel {
  id: number;
  name: string;
}

export interface CourseFilterModel {
  courseTypes: CourseTypeModel[];
  // ageGroups: AgeGroupModel[]
  semesters: SemesterModel[];
}

export interface SemesterModel {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
}

export interface CourseShoppingBasketModel {
  shoppingBasket: ShoppingBasketModel;
  deletedItems: ShoppingBasketItemModel[];
  dirty: boolean;
  globalAmount: number;
}

export interface TimeIntervalModel {
  from: string;
  to: string;
}

export interface CourseModel extends WithAgeRestrictionModel {
  id: number;
  centerId: number;
  centerName: string;
  bookingIds?: { id: number; centerId: number }[];
  name: string;
  startDate: string;
  stopDate: string;
  activity: ActivityModel;
  capacity: number;
  booked: number;
  timeIntervals: TimeIntervalModel[];
  weekDays: WeekDaysModel[];
  instructorNames: string[];
  ageRestrictionMin?: number;
  ageRestrictionMax?: number;
  expanded?: boolean; // UI ONLY
}

export interface CourseBookingInfoModel {
  // hasActiveSubscription: boolean;
  startBooking?: BookingModel;
}

export interface CourseProductModel {
  id: number;
  centerId: number;
  name: string;
  type: string;
  clips: number;
  price: number;
  currency: string;
  paymentOptions: PaymentOptionsModel[];
}

export interface CourseAvailableProductModel {
  courseProducts: CourseProductModel[];
  existingProduct?: string;
}

export interface PaymentOptionsModel {
  externalId: string;
  name: string;
  type: string;
  numberOfInstallments: number;
  firstInstallmentPaidInPOS: boolean;
  initialAmount: number;
  amountToPay?: number;
  vatAmount?: number;
}

export interface WithAgeRestrictionModel {
  ageRestrictionMin?: number;
  ageRestrictionMax?: number;
}

export interface CampsShoppingBasketModel {
  programs: CampsProgramModel[];
  totalPrice: number;
  availablePaymentOptions: PaymentOptionsModel[] | "NOT_SET";
}

export interface CampsProgramModel {
  selectedMemberId: number;
  selectedMemberCenterId: number;
  selectedMemberFirstName: string;
  selectedMemberLastName: string;
  campName: string;
  bookingProgramId: number;
  campsDetail: CampDetailsModel;
  orderDetails: {
    activityId: number;
    activityName: string;
    productId: number;
    productCenterId: number;
    productName: string;
    quantity: number;
    normalPrice: number;
    totalAmount: number;
  }[];
  totalProgramAmount: number;
  deleted?: boolean;
}

export interface OrderModel {
  bookingProgramId: number;
}

export interface CampModel extends WithAgeRestrictionModel {
  centerId: number;
  centerName: string;
  bookingProgramIds: number[];
  name: string;
  ageRestrictionMin?: number;
  ageRestrictionMax?: number;
  campDetails?: CampDetailsModel[];
  loading?: boolean; // ui only
  error?: string; // ui only
  expanded?: boolean; // UI ONLY
  questionnaireCampaignKeys?: number[];
}

export interface CampDetailsModel extends WithAgeRestrictionModel {
  bookingProgramId: number;
  name: string;
  description: string;
  centerId: number;
  centerName: string;
  currency: string;
  startDate: string;
  endDate: string;
  price: number;
  earliestTime: string;
  latestTime: string;
  ageRestrictionMin?: number;
  ageRestrictionMax?: number;
  capacity: number;
  available: number;
  schedules: CampBookingModel[];
  questionnaireCampaignKeys?: number[];
}

export interface CampBookingModel {
  bookingId: number;
  centerId: number;
  centerName: string;
  name: string;
  date: string;
  startTime: string;
  endTime: string;
  roomNames: string[];
  activityId: number;
  bookingProgramId: number;
  capacity: number;
  available: number;
  activityName: string;
  product: {
    productId: number;
    productCenterId: number;
    productName: string;
    quantity: number;
    normalPrice: string;
    totalAmount: string;
  };
  type: "MAIN" | "ELECTIVE";
  selected?: boolean; // UI ONLY
}

export type CourseTypeEnumModel = "COURSES" | "CAMPS";
export const CourseTypeEnumModel = {
  COURSES: "COURSES" as CourseTypeEnumModel,
  CAMPS: "CAMPS" as CourseTypeEnumModel,
};

export interface CourseToStandbyListParamsModel {
  personKey: CompositeKeyModel;
  bookingProgramId: number;
  ownerKey?: CompositeKeyModel;
}
