import store from '@/store';
import { Vue } from 'vue-class-component';
import { Inject, Watch } from 'vue-property-decorator';
import ConfigurationService from '@/services/configuration.service';
import { getLookAndFeel } from '@/shared/utils';

export default class TheFooter extends Vue {
  logo: string | null = null;

  links: string | null = null;

  @Inject('configurationService')
  public configurationService!: ConfigurationService;

  @Watch('$store.state.config', { immediate: true, deep: true })
  updatedConfig(): void {
    this.logo = getLookAndFeel(store.state.config, 'siteLogo');

    this.links = getLookAndFeel(store.state.config, 'footerLinks');
  }
}
