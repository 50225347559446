import {
  AnswerModel,
  QuestionnaireAnswerModel,
  QuestionnaireModel,
  QuestionnaireTemplateModel,
  QuestionnaireTypeModel,
  QuestionnairesGetAnswersForPersonReqModel,
  RelatedPersonModel,
  UserModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

interface PostDataStruct {
  questionnaireId: number;
  finalAnswers: AnswerModel[];
  selectedUserId: number | undefined;
  selectedUserCenterId: number | undefined;
  selectedUserExternalId: string | undefined;
}
export default class QuestionnaireService {
  constructor() {}

  getActiveQuestionnaires(
    questionnaireTypeModel: QuestionnaireTypeModel,
    mandatory: boolean,
    centerId: number
  ): Promise<Array<QuestionnaireModel>> {
    return backendApi.post('questionnaire/get-active-questionnaires', {
      questionnaireTypeModel,
      mandatory,
      centerId,
    });
  }

  getAnswersForPerson(member: RelatedPersonModel | UserModel | null, includeCompleted: boolean): Promise<Array<QuestionnaireAnswerModel>> {
    const postData: QuestionnairesGetAnswersForPersonReqModel = {
      selectedUserId: member?.userId,
      selectedUserCenterId: member?.centerId,
      selectedUserExternalId: member?.externalId,
      includeCompleted,
    };
    return backendApi.post('questionnaire/get-answer-for-person', postData);
  }

  getCampQuestionnaires(questionnaireCampaignKeys: number[]): Promise<QuestionnaireModel[]> {
    return backendApi.post('questionnaire/get-questionnaire-campaigns', {
      questionnaireCampaignKeys,
    });
  }

  getQuestionnaireTemplate(questionnaireTemplateId: number): Promise<QuestionnaireTemplateModel> {
    return backendApi.post('questionnaire/get-questionnaire-template', {
      questionnaireTemplateId,
    });
  }

  submitAnswers(questionnaireId: number, finalAnswers: AnswerModel[], selectedMember: RelatedPersonModel | UserModel | null = null) {
    const postData: PostDataStruct = {
      questionnaireId: questionnaireId,
      finalAnswers,
      selectedUserId: undefined,
      selectedUserCenterId: undefined,
      selectedUserExternalId: undefined,
    };
    if (selectedMember) {
      postData.selectedUserExternalId = selectedMember.externalId;
      postData.selectedUserId = selectedMember.userId;
      postData.selectedUserCenterId = selectedMember.centerId;
    }
    return backendApi.post('questionnaire/submit-answers', postData);
  }
}
