import {
  ClipcardModel,
  ClipcardSubscriptionModel,
  PaymentAgreenentModel,
  PersonClipcardModel,
  ShoppingBasketModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class ClipcardService {
  constructor() {}

  loadClipcardsForUser(
    selectedUserId: number,
    selectedUserCenterId: number,
    selectedCenterId: number | null,
    productGlobalId?: string
  ): Promise<ClipcardModel[]> {
    return backendApi.post('clipcards/load-clipcards-for-user', {
      selectedUserId,
      selectedUserCenterId,
      selectedCenterId,
      productGlobalId,
    });
  }

  getPersonAndRelativesClipcards(): Promise<PersonClipcardModel[]> {
    return backendApi.post('clipcards/load-person-and-relatives-clipcards');
  }
  loadPersonClipcards(selectedUserId: number, selectedUserCenterId: number): Promise<PersonClipcardModel[]> {
    return backendApi.post('clipcards/load-person-clipcards', { selectedUserId, selectedUserCenterId });
  }

  loadClipcardsForUserById(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined,
    priceOverride: number | null,
    productGlobalId?: string
  ): Promise<ClipcardModel> {
    return backendApi.post('clipcards/load-clipcards-for-user-by-id', {
      selectedUserId,
      selectedUserCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
      priceOverride,
      productGlobalId,
    });
  }

  loadClipcardsForUserByIdForRsBooking(
    selectedUserId: number,
    selectedUserCenterId: number,
    activityId: number,
    activityCenterId: number,
    date: string,
    startTime: string,
    duration: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined
  ): Promise<ClipcardModel> {
    return backendApi.post('clipcards/load-clipcards-for-user-by-id-for-rsbooking', {
      selectedUserId,
      selectedUserCenterId,
      activityId,
      activityCenterId,
      date,
      startTime,
      duration,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
    });
  }

  loadClipcardsForUserByIdForClassBooking(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingId: number,
    bookingCenterId: number,
    clipcardId: number,
    clipcardCenterId: number,
    clipcardSize: number | undefined
  ): Promise<ClipcardModel> {
    return backendApi.post('clipcards/load-clipcards-for-user-by-id-for-class-booking', {
      selectedUserId,
      selectedUserCenterId,
      bookingId,
      bookingCenterId,
      clipcardId,
      clipcardCenterId,
      clipcardSize,
    });
  }

  loadShoppingBasketDetailsForCCSubscriptions(
    selectedUserId: number,
    selectedUserCenterId: number,
    shoppingBasketId: string,
    clipcardSubscriptionId: number,
    clipcardSubscriptionCenterId: number,
    clipcardSubscriptionSize: number | undefined
  ): Promise<ClipcardSubscriptionModel> {
    return backendApi.post('clipcards/load-shopping-basket-details-for-cc-subscription', {
      selectedUserId,
      selectedUserCenterId,
      shoppingBasketId,
      clipcardSubscriptionId,
      clipcardSubscriptionCenterId,
      clipcardSubscriptionSize,
    });
  }

  createShoppingBasketDetailsForCCSubscriptions(
    selectedUserId: number,
    selectedUserCenterId: number,
    clipcardSubscriptionId: number,
    clipcardSubscriptionCenterId: number,
    clipcardSubscriptionSize: number,
    priceOverride: number | null
  ): Promise<ShoppingBasketModel> {
    return backendApi.post('clipcards/create-shopping-basket-for-cc-subscription', {
      selectedUserId,
      selectedUserCenterId,
      clipcardSubscriptionId,
      clipcardSubscriptionCenterId,
      clipcardSubscriptionSize,
      priceOverride,
    });
  }

  loadClipcardsForAnonymous(selectedCenterId: number | null): Promise<ClipcardModel[]> {
    return backendApi.post('clipcards/load-clipcards-for-anonymous', { selectedCenterId });
  }
}
