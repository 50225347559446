import store from '@/store';

// the user is active and has a subscription:
export function userIsActive() {
  return (
    store.state.user &&
    store.state.user.status !== 'LEAD' &&
    store.state.user.status !== 'INACTIVE' &&
    store.state.user.status !== 'CONTACT' &&
    store.state.user.status !== 'DUPLICATE' &&
    store.state.user.status !== 'TRANSFERRED' &&
    store.state.user.status !== 'PROSPECT'
  );
}

export function joinFlowStepTitle(step: number, $t: (a: string) => string): string {
  switch (step) {
    case 1:
      return $t('subscription.selectYourCenter');
    case 2:
      return $t('subscription.selectYourPlan');
    case 3:
      return $t('subscription.enterYourDetails');
    case 3.5:
      return $t('questionnaire.questionnaire');
    case 4:
      return $t('subscription.enterPaymentDetails');
    default:
      return '';
  }
}

export function changeFlowStepTitle(step: number, $t: (a: string) => string): string {
  switch (step) {
    case 1:
      return $t('subscription.selectNewPlan');
    case 2:
      return $t('subscription.updateDetails');
    case 3:
      return $t('subscription.enterPaymentDetails');
    /* istanbul ignore next */
    default:
      return '';
  }
}
