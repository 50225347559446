import { Options, Vue } from 'vue-class-component';
import { Inject } from 'vue-property-decorator';

import ConfigurationService from '@/services/configuration.service';
import ConfigError from '@/components/config/config-error.component.vue';
import ConfigLoading from '@/components/config/config-loading.component.vue';
import TheHeader from '@/components/header/header.component.vue';
import TheFooter from '@/components/footer/footer.component.vue';

@Options({
  components: {
    ConfigError,
    ConfigLoading,
    TheHeader,
    TheFooter,
  },
})
export default class App extends Vue {
  @Inject('configurationService')
  public configurationService!: ConfigurationService;

  beforeMount(): void {
    // config is loaded when the router changes, because we might to reload the config if for example we have an sso tocken
    // otherwise the same config is used
    // this.configurationService.loadConfiguration().catch(() => {});
  }
}
