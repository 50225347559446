import { FamilyMemberTypeModel, ApiGenderModel } from "./person.model";

export const SSO_TOKEN_HEADER = "sso-token";
export const SSO_USER_HEADER = "sso-user";
export const SSO_MAPI_TOKEN_HEADER = "sso-mapi-token";
export const SSO_HEADER_TYPE_HEADER = "sso-header-type";
export const SSO_COMPANY_HEADER = "sso-company";

export type UserProfileEnum = "MEMBER" | "STAFF" | "MANAGER" | "COMPANY";
export const UserProfileEnum = {
  MemberLevel: "MEMBER" as UserProfileEnum,
  StaffLevel: "STAFF" as UserProfileEnum,
  ManagerLevel: "MANAGER" as UserProfileEnum,
  CompanyLevel: "COMPANY" as UserProfileEnum,
};

export type UseRelationEnum = "FAMILY" | "STAFF";
export const UseRelationEnum = {
  FAMILY: "FAMILY" as UseRelationEnum,
  STAFF: "STAFF" as UseRelationEnum,
};

export type PersonType =
  | "PRIVATE"
  | "STUDENT"
  | "STAFF"
  | "FRIEND"
  | "CORPORATE"
  | "ONEMANCORPORATE"
  | "FAMILY"
  | "SENIOR"
  | "GUEST"
  | "CHILD"
  | "EXTERNAL_STAFF";
export type PersonStatus =
  | "LEAD"
  | "ACTIVE"
  | "INACTIVE"
  | "TEMPORARYINACTIVE"
  | "TRANSFERRED"
  | "DUPLICATE"
  | "PROSPECT"
  | "CONTACT";

export type SSOHeaderEnum = "NORMAL" | "SMALL" | "TINY";
export const SSOHeaderEnum = {
  NORMAL: "NORMAL" as SSOHeaderEnum,
  SMALL: "SMALL" as SSOHeaderEnum,
  TINY: "TINY" as SSOHeaderEnum,
};

export interface UserModel {
  centerId: number;
  userId: number;
  externalId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile?: string;
  address?: AddressModel;
  gender: ApiGenderModel; // Required by spotii api
  birthday?: string;
  accessLevel: UserProfileEnum[];
  personType?: PersonType;
  status?: PersonStatus;
  relations: RelatedPersonModel[];
  isSso?: boolean | "SELECTED_PROFILE_TAB_ONLY";
  ssoHeaderType?: SSOHeaderEnum;
  mapiToken?: string;
  expiry?: Date;
}

export interface PersonModel extends UserModel {
  age?: number;
  centerName?: string;
}

export interface TokenUserModel {
  user: UserModel;
  token?: string;
}

export interface RelatedPersonModel {
  userId: number;
  centerId: number;
  externalId?: string;
  memberType?: FamilyMemberTypeModel;
  firstName?: string;
  lastName?: string;
  age?: number;
  birthday?: string;
}

export interface AddressModel {
  address1?: string;
  address2?: string;
  address3?: string;
  zip?: string;
  zipName?: string;
  country?: string;
  coName?: string;
  county?: string;
  province?: string;
}
