import HtmlTextarea from '@/components/ui/html-textarea.component.vue';
import { Options, Vue } from 'vue-class-component';
import { StrapLineConfig, StraplineContent } from '../../../../../common/src';
import store from '@/store';
import { filterOutContentByUrl, sanitizeHtml } from '@/shared/utils';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    HtmlTextarea,
  },
})
export default class TheStrapLine extends Vue {
  items: { [key: string]: StrapLineConfig } = {};
  selectedLanguage = store.state.currentLocale!.locale;
  visibleItems: { [key: string]: { backgroundColor: string; straplineContent: StraplineContent } } = {};

  // Need to refresh whenever the route changes or the selected language changes
  @Watch('$route.path', { immediate: true, deep: true })
  @Watch('$store.state.currentLocale.locale', { immediate: true, deep: true })
  refreshStrapLines() {
    this.selectedLanguage = store.state.currentLocale!.locale;
    this.visibleItems = this.filterStraplinesToShow();
  }

  created() {
    this.updateConfig();
  }

  @Watch('$store.state.config', { immediate: true, deep: true })
  private updateConfig() {
    this.items = store.state.config?.strapLines?.htmlContents as { [key: string]: StrapLineConfig };
    this.visibleItems = this.filterStraplinesToShow();
  }

  private filterStraplinesToShow() {
    const tempItems: { [key: string]: { backgroundColor: string; straplineContent: StraplineContent } } = {};
    for (const key in this.items) {
      if (Object.prototype.hasOwnProperty.call(this.items, key)) {
        if (filterOutContentByUrl(key, this.$route.path)) {
          // Only keep the ones corresponding to the current route
          let strapLineContents: StraplineContent[] = this.items[key].straplineContent;
          // Only keep the ones corresponding to the selected language
          strapLineContents = strapLineContents.filter(strapLineContent => strapLineContent.language === this.selectedLanguage);
          // Construct the results if there are any
          if (strapLineContents[0]?.value) {
            tempItems[key] = {
              backgroundColor: this.items[key].backgroundColor,
              straplineContent: {
                language: strapLineContents[0].language,
                value: sanitizeHtml(strapLineContents[0].value),
              },
            };
          }
        }
      }
    }
    return tempItems;
  }

  get numberOfItems(): number {
    return Object.keys(this.visibleItems).length;
  }
}
