import store from '@/store';

// format date from Exerp's yyyy-mm-dd to localised date format: Example: Mar 12, 2020
export function formatDateStringToLocale(dateString: string): string {
  const date = new Date(dateString + 'T00:00:00');
  return date.toLocaleDateString(store.state.currentLocale!.dateFormatFull, { year: 'numeric', month: 'short', day: 'numeric' });
}

export function formatTimeStringToLocale(timeString: string): string {
  const date = new Date();
  // set the time to the timeString
  date.setHours(parseInt(timeString.substr(0, 2), 10));
  date.setMinutes(parseInt(timeString.substr(3, 2), 10));
  return date.toLocaleTimeString(store.state.currentLocale!.dateFormat, { hour: 'numeric', minute: 'numeric' });
}

export function formatFullDateTimeStringToLocale(fullDateString: string): string {
  const date = new Date(fullDateString);
  return date.toLocaleDateString(store.state.currentLocale!.dateFormatFull, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function weekDayString(date: string): string {
  return new Date(date + 'T00:00:00').toLocaleDateString(store.state.currentLocale!.dateFormat, {
    weekday: 'long',
  });
}

export function dayMonthString(date: string): string {
  return new Date(date + 'T00:00:00').toLocaleDateString(store.state.currentLocale!.dateFormat, {
    month: 'short',
    day: 'numeric',
  });
}

export function addOneDay(date: Date): Date {
  return new Date(date.getTime() + 24 * 60 * 60 * 1000);
}

// format input date to yyyy-mm-dd
export function formatDateToExerp(date: Date): string {
  const dateCopy = new Date(date);
  dateCopy.setHours(0, 0, 0, 0);
  const year = dateCopy.getFullYear();
  const month = dateCopy.getMonth() + 1;
  const day = dateCopy.getDate();
  return yearMonthDayToString(year, month, day);
}

export function formatDateTimeToExerp(date: Date): string {
  const dateCopy = new Date(date);
  dateCopy.setSeconds(0, 0);
  const year = dateCopy.getFullYear();
  const month = dateCopy.getMonth() + 1;
  const day = dateCopy.getDate();
  const hours = dateCopy.getHours();
  const minutes = dateCopy.getMinutes();
  return yearMonthDayToString(year, month, day) + 'T' + hoursMinutesToString(hours, minutes);
}

export function formatDateToUTC(date: Date): string {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  return yearMonthDayToString(year, month, day);
}

function yearMonthDayToString(year: number, month: number, day: number): string {
  return `${year}-${zeroPad(month)}-${zeroPad(day)}`;
}

export function hoursMinutesToString(hours: number, minutes: number) {
  return `${zeroPad(hours)}:${zeroPad(minutes)}:00`;
}

function zeroPad(n: number) {
  return n < 10 ? '0' + n : n;
}

export function adjustDateOffset(date: Date, timeZoneOffset: number): Date {
  // add the offset in minutes to the date
  const newDate = new Date(date.getTime() + timeZoneOffset * 60 * 1000);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

// valid for dates in the format yyyy-mm-dd; can be improved
export function checkIfDateIsValid(dateStr: string): boolean {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
}
