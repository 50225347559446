import TheStrapLine from '@/components/header/strap-line/strap-line.component.vue';
import store from '@/store';
import { Options, Vue } from 'vue-class-component';
import { getLanguage, getLookAndFeel, handleSignOut } from '@/shared/utils';
import { Inject, Watch } from 'vue-property-decorator';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { MenuIcon, XIcon, TranslateIcon, UserIcon, DotsHorizontalIcon } from '@heroicons/vue/outline';
import UserService from '@/services/user.service';
import ConfigurationService from '@/services/configuration.service';
import LanguageDropdown from './language-dropdown.component.vue';
import { hasAccess, redirectUrl, visibleMenu } from '@/shared/route-utils';
import ContentBlock from '@/components/shared/ContentBlock.vue';
import { StrapLineConfig } from '../../../../common/src';

@Options({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    TranslateIcon,
    UserIcon,
    DotsHorizontalIcon,
    LanguageDropdown,
    ContentBlock,
    TheStrapLine,
  },
})
export default class TheHeader extends Vue {
  @Inject('userService')
  public userService!: UserService;

  @Inject('configurationService')
  public configurationService!: ConfigurationService;

  banner: string | null = null;
  logo: string | null = null;
  title: string | null = null;
  more = false;
  showStrapLine = false;

  navigation: { name: string; route: string; cy: string; access: string; order: number }[] = [];
  greedyNavigationHiddenItems = new Set<number>();

  @Watch('$store.state.user', { immediate: true, deep: true })
  updatedUser(): void {
    this.navigation.length = 0;
    this.$router.getRoutes().forEach(route => {
      if (route.meta && route.meta.navbarName) {
        if (hasAccess(route, store.state.user) && visibleMenu(route, store.state.config)) {
          this.navigation.push({
            name: String(route.meta.navbarName),
            route: route.path.replace('/:selectedMemberKey', ''),
            cy: String(route.meta.cy),
            access: String(route.meta.access),
            order: Number((store.state.config!.menu as any)[String(route.meta?.sysprop)]),
          });
        }
      }
    });

    // sort this.navigation by system properties position
    this.navigation.sort((a, b) => {
      return a.order - b.order;
    });
  }

  get natigationItems() {
    if (this.$route.meta.hideNavbarMenu) {
      return [];
    }
    return this.navigation;
  }

  get hideNavbarMenuItems() {
    return this.$route.meta.hideNavbarMenu;
  }

  get isErrorPage(): boolean {
    return this.$route.name === 'Error404' || this.$route.name === 'UnauthorizedError' || this.$route.name === 'SSOTokenError';
  }

  @Watch('$route', { immediate: true, deep: true })
  updatedRoute(): void {
    this.updatedConfig();

    // The case where the title is not set cannot be tested
    /* istanbul ignore else */
    if (this.$route.meta.title) {
      this.title = String(this.$route.meta.title);
      window.document.title = this.$t(String(this.$route.meta.title));
    } else {
      this.title = window.document.title = 'Exerp';
    }
  }

  @Watch('$store.state.config', { immediate: true, deep: true })
  updatedConfig(): void {
    this.logo = getLookAndFeel(store.state.config, 'siteLogo');
    this.showStrapLine = !this.isErrorPage && !!store.state.config!.strapLines?.htmlContents;
    // Router Meta
    this.banner = getLookAndFeel(store.state.config, this.$route.meta.banner);

    // Update greedy navigation
    this.greedyNav();
  }

  signOut(): void {
    handleSignOut(this.userService, this.configurationService);
  }

  beforeMount(): void {
    const currentLanguage = getLanguage(localStorage.language);
    this.$i18n.locale = currentLanguage.code;
    store.commit('currentLocale', currentLanguage);
  }

  gotoHomeUrl(): void {
    let redirect: string | undefined;
    if (store.state.user) {
      redirect = store.state.config?.redirects.homeMember;
    } else {
      redirect = store.state.config?.redirects.homeAnonymous;
    }
    if (redirect) {
      redirectUrl(redirect);
    }
  }

  mounted() {
    const greedyNavResizeObserver = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        if (entries.length) {
          this.greedyNav();
        }
      });
    });
    const greedyNav = document.getElementById('greedy-nav') as HTMLElement;
    if (greedyNav) {
      greedyNavResizeObserver.observe(greedyNav);
    }
  }

  greedyNav() {
    const padding = 12;
    const greedyNav = document.getElementById('greedy-nav') as HTMLElement;
    if (greedyNav) {
      const greedyNavLeft = greedyNav.getBoundingClientRect().left;
      const greedyNavWidth = greedyNav.clientWidth;
      const greedyNavLinks = greedyNav.children as HTMLCollectionOf<HTMLElement>;
      let offset = greedyNavLeft;
      for (let i = 0; i < greedyNavLinks.length; i++) {
        const navLink = greedyNavLinks[i];
        if (navLink.offsetLeft + navLink.clientWidth > greedyNavWidth + padding / 2) {
          navLink.style.visibility = 'hidden';
          this.greedyNavigationHiddenItems.add(i);
          this.more = true;
        } else {
          offset += navLink.clientWidth + padding;
          navLink.style.visibility = 'visible';
          this.greedyNavigationHiddenItems.delete(i);
          this.more = false;
        }
      }
      this.$nextTick(() => {
        const moreMenu = document.getElementById('more-menu') as HTMLElement;
        if (moreMenu) {
          moreMenu.style.left = `${offset - 40}px`;
        }
      });
    }
  }
}
