import { ShoppingBasketModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class ShoppingBasketService {
  constructor() {}

  getShoppingBasket(shoppingBasketId: string): Promise<ShoppingBasketModel> {
    return backendApi.post('shopping-basket/get-shopping-basket', { shoppingBasketId });
  }
}
