import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap bg-primary-500 sm:max-w-7xl md:max-w-none mx-auto px-6 lg:px-8 text-sm" }
const _hoisted_2 = { class: "w-full md:w-1/3 pt-4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full md:w-1/3 pt-4 md:pb-4 text-opposite-500 text-center" }
const _hoisted_5 = {
  key: 0,
  "data-cy": "footer-links"
}
const _hoisted_6 = ["href", "data-cy"]
const _hoisted_7 = { class: "w-full md:w-1/3 py-4 text-opposite-500 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "h-4 w-auto",
        src: _ctx.logo,
        alt: "Logo",
        "data-cy": "footer-logo"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.links)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (url, name) => {
              return (_openBlock(), _createElementBlock("p", { key: url }, [
                _createElementVNode("a", {
                  href: url,
                  target: "_blank",
                  "data-cy": 'footer-link-' + name
                }, _toDisplayString(_ctx.$t(`footer.${name}`)) + " : " + _toDisplayString(url), 9, _hoisted_6)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('footer.copyright')), 1)
  ]))
}