import { ParticipationModel, ActivitiesPageModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class DashboardService {
  constructor() {}

  getScheduleByCurrentPerson(
    selectedUserId: number,
    selectedUserCenterId: number,
    listOfActivities: string[],
    selectAllMembers: boolean
  ): Promise<ParticipationModel[]> {
    return backendApi.post(
      'dashboard/schedule-by-current-person',
      { selectedUserId, selectedUserCenterId, listOfActivities, selectAllMembers },
      {}
    );
  }

  getActivitiesByCurrentPerson(
    selectedUserId: number,
    selectedUserCenterId: number,
    date: string,
    time: string,
    size: number,
    listOfActivities: string[],
    selectAllMembers: boolean
  ): Promise<ActivitiesPageModel> {
    return backendApi.post(
      'dashboard/activities-by-current-person',
      { selectedUserId, selectedUserCenterId },
      { date, time, size, listOfActivities, selectAllMembers }
    );
  }
}
