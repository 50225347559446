import { createI18n } from 'vue-i18n';

export interface Language {
  code: string; // 'en' or 'en_US'
  locale: string; // 'en' or 'en_US'
  longName: string; // 'English'
  shortName: string; // 'En'
  numberFormat: string; // en_US
  dateFormat: string; // en_US
  dateFormatFull: string; // es_ES or pt
  order: number;
  direction: 'rtl' | 'ltr';
}

export function loadEnglishLocale(): any {
  return require.context('./locales')('./en.json').default;
}

const i18n = createI18n({
  // legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: loadEnglishLocale(),
  },
});

export default i18n;
