import { ApiPersonKeyModel, CompositeSubKeyModel } from "..";

export interface QuestionnaireModel {
  id?: number;
  templateId?: number;
  from?: string;
  to?: string;
  type?: QuestionnaireTypeModel;
  name?: string;
  rank?: number;
  mandatory?: boolean;
}

export type QuestionnaireTypeModel =
  | "NewPerson"
  | "NewGuest"
  | "NewSubscription"
  | "EndSubscription"
  | "Survey"
  | "Health"
  | "Staff";
export const QuestionnaireTypeModel = {
  NewPerson: "NewPerson" as QuestionnaireTypeModel,
  NewGuest: "NewGuest" as QuestionnaireTypeModel,
  NewSubscription: "NewSubscription" as QuestionnaireTypeModel,
  EndSubscription: "EndSubscription" as QuestionnaireTypeModel,
  Survey: "Survey" as QuestionnaireTypeModel,
  Health: "Health" as QuestionnaireTypeModel,
  Staff: "Staff" as QuestionnaireTypeModel,
};

export type QuestionnaireTemplateStatusModel = "New" | "Active" | "Inactive";
export const QuestionnaireTemplateStatusModel = {
  New: "New" as QuestionnaireTemplateStatusModel,
  Active: "Active" as QuestionnaireTemplateStatusModel,
  Inactive: "Inactive" as QuestionnaireTemplateStatusModel,
};

export type QuestionTypeModel =
  | "FreeText"
  | "SelectSingleOption"
  | "SelectMultipleOptions"
  | "ExtendedAttribute";
export const QuestionTypeModel = {
  FreeText: "FreeText" as QuestionTypeModel,
  SelectSingleOption: "SelectSingleOption" as QuestionTypeModel,
  SelectMultipleOptions: "SelectMultipleOptions" as QuestionTypeModel,
  ExtendedAttribute: "ExtendedAttribute" as QuestionTypeModel,
};

export interface OptionModel {
  id?: number;
  text?: string;
}

export interface AnswerModel {
  questionId: number;
  text?: string;
  selectedOption?: OptionModel;
  selectedOptions?: Array<OptionModel>;
}

export interface QuestionnaireAnswerModel {
  answerId: CompositeSubKeyModel;
  personId: ApiPersonKeyModel;
  questionnaireId: number;
  templateId: number;
  time: string;
  completed: boolean;
  journalEntryId?: number;
  expirationDate?: string;
  answers: Array<AnswerModel>;
}

export interface QuestionnairesGetAnswersForPersonReqModel {
  selectedUserId: number | undefined;
  selectedUserCenterId: number | undefined;
  selectedUserExternalId: string | undefined;
  includeCompleted?: boolean;
}

export interface QuestionModel {
  id: number;
  blocked?: boolean;
  text?: string;
  type: QuestionTypeModel;
  options?: Array<OptionModel>;
  defaultOption?: OptionModel;
  required?: boolean;
  requiredForAnswer?: AnswerModel;
}

export interface QuestionnaireTemplateModel {
  id?: number;
  name?: string;
  heading?: string;
  text?: string;
  status?: QuestionnaireTemplateStatusModel;
  questions?: Array<QuestionModel>;
}
