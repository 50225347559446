type Rgb = {
  r: number;
  g: number;
  b: number;
};

export function setColor(
  baseColor: string,
  colorType:
    | 'primary'
    | 'secondary'
    | 'highlight'
    | 'makeAwareColor'
    | 'bannerSummaryBackgroundColor'
    | 'bannerSummaryTextColor'
    | 'bannerSummaryButtonBackgroundColor'
): boolean {
  if (!baseColor || !/^#?[0-9A-F]{6}$/i.test(baseColor)) {
    return false;
  }
  userColor(baseColor, colorType);
  return true;
}

function userColor(
  baseColor: string,
  colorType:
    | 'primary'
    | 'secondary'
    | 'highlight'
    | 'makeAwareColor'
    | 'bannerSummaryBackgroundColor'
    | 'bannerSummaryTextColor'
    | 'bannerSummaryButtonBackgroundColor'
) {
  setColorVar(500, colorType, `#${baseColor}`.replace('##', '#'));
  const intensityMap: {
    [key: number]: number;
  } = {
    50: 0.95,
    100: 0.9,
    200: 0.75,
    300: 0.6,
    400: 0.3,
    600: 0.9,
    700: 0.75,
    800: 0.6,
    900: 0.49,
  };

  [50, 100, 200, 300, 400].forEach(level => {
    setColorVar(level, colorType, lighten(baseColor, intensityMap[level]));
  });

  [600, 700, 800, 900].forEach(level => {
    setColorVar(level, colorType, darken(baseColor, intensityMap[level]));
  });
}

function setColorVar(
  level: number,
  colorType:
    | 'primary'
    | 'secondary'
    | 'highlight'
    | 'makeAwareColor'
    | 'bannerSummaryBackgroundColor'
    | 'bannerSummaryTextColor'
    | 'bannerSummaryButtonBackgroundColor',
  value: string
) {
  document.documentElement.style.setProperty(`--color-${colorType}-${level}`, value);
  // get RGB from 'value'
  const rgb = hexToRgb(value);
  if (colorType === 'primary') {
    if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) {
      document.documentElement.style.setProperty(`--color-${colorType}-${level}-opposite`, '#0D0D0D'); // Not quite black
    } else {
      document.documentElement.style.setProperty(`--color-${colorType}-${level}-opposite`, '#F1F1F1'); // Not quite white
    }
  }
}

function lighten(hex: string, intensity: number): string {
  const color = hexToRgb(`#${hex}`);
  const r = Math.round(color.r + (255 - color.r) * intensity);
  const g = Math.round(color.g + (255 - color.g) * intensity);
  const b = Math.round(color.b + (255 - color.b) * intensity);
  return rgbToHex(r, g, b);
}

function darken(hex: string, intensity: number): string {
  const color = hexToRgb(hex);
  const r = Math.round(color.r * intensity);
  const g = Math.round(color.g * intensity);
  const b = Math.round(color.b * intensity);
  return rgbToHex(r, g, b);
}

function hexToRgb(hex: string): Rgb {
  const sanitizedHex = hex.replaceAll('##', '#');
  const colorParts = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(sanitizedHex);

  const [, r, g, b] = colorParts!;

  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
  } as Rgb;
}

function rgbToHex(r: number, g: number, b: number): string {
  const toHex = (c: number) => `0${c.toString(16)}`.slice(-2);
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}
