import {
  BookingModel,
  CourseAvailableProductModel,
  CourseBookingInfoModel,
  CourseFilterModel,
  CourseModel,
  CourseProductModel,
  CourseShoppingBasketModel,
  CourseTypeEnumModel,
  TimeIntervalModel,
  WeekDaysModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class CoursesService {
  constructor() {}

  getCourseFilters(centerId: number, type: CourseTypeEnumModel): Promise<CourseFilterModel> {
    return backendApi.get('courses/get-courses-filters', { centerId, type });
  }

  getCourseShoppingBasket(): Promise<CourseShoppingBasketModel> {
    return backendApi.get('courses/get-course-shopping-basket', {});
  }

  getCourseProducts(
    selectedUserId: number,
    selectedUserCenterId: number,
    startBookingId: number,
    startBookingCenterId: number
  ): Promise<CourseAvailableProductModel> {
    return backendApi.get('courses/get-course-products', { selectedUserId, selectedUserCenterId, startBookingId, startBookingCenterId });
  }

  getCourseDetails(
    dateIntervalFrom: string,
    dateIntervalTo: string,
    timeIntervals: { from: string; to: string }[],
    activityId: number,
    courseCenterId: number,
    bookingIds: { id: number; centerId: number }[]
  ): Promise<BookingModel[]> {
    return backendApi.post('courses/get-course-details', {
      dateIntervalFrom,
      dateIntervalTo,
      timeIntervals,
      activityId,
      courseCenterId,
      bookingIds,
    });
  }

  getCourseBookingInfos(
    dateIntervalFrom: string,
    dateIntervalTo: string,
    timeIntervals: { from: string; to: string }[],
    activityId: number,
    bookingIds: { id: number; centerId: number }[]
  ): Promise<CourseBookingInfoModel> {
    return backendApi.post('courses/get-course-booking-infos', { dateIntervalFrom, dateIntervalTo, timeIntervals, activityId, bookingIds });
  }

  addCourseToShoppingBasket(
    selectedUserId: number,
    selectedUserCenterId: number,
    startBookingId: number,
    startBookingCenterId: number,
    productId: number,
    productCenterId: number,
    productType: string,
    courseId: number,
    courseDisplay: {
      // FIXME: we should be able to get this from the course id!!!!
      name: string;
      startDate: string;
      stopDate: string;
      timeIntervals: TimeIntervalModel[];
      weekDays: string[];
      instructorNames: string[];
    },
    externalId: string | undefined
  ): Promise<CourseShoppingBasketModel> {
    return backendApi.post('courses/add-course-to-shopping-basket', {
      selectedUserId,
      selectedUserCenterId,
      startBookingId,
      startBookingCenterId,
      productId,
      productCenterId,
      productType,
      courseId,
      courseDisplay,
      externalId,
    });
  }

  updateCourseToShoppingBasket(
    selectedUserId: number,
    selectedUserCenterId: number,
    startBookingId: number,
    startBookingCenterId: number,
    productId: number,
    productCenterId: number,
    productType: string,
    courseId: number,
    courseDisplay: {
      // FIXME: we should be able to get this from the course id!!!!
      name: string;
      startDate: string;
      stopDate: string;
      timeIntervals: TimeIntervalModel[];
      weekDays: string[];
      instructorNames: string[];
    },
    externalId: string | undefined,
    itemKey: string
  ): Promise<CourseShoppingBasketModel> {
    return backendApi.post('courses/update-course-to-shopping-basket', {
      selectedUserId,
      selectedUserCenterId,
      startBookingId,
      startBookingCenterId,
      productId,
      productCenterId,
      productType,
      courseId,
      courseDisplay,
      externalId,
      itemKey,
    });
  }

  bookCourse(selectedUserId: number, selectedUserCenterId: number, startBookingId: number, startBookingCenterId: number): Promise<void> {
    return backendApi.post('courses/book-course', {
      selectedUserId,
      selectedUserCenterId,
      startBookingId,
      startBookingCenterId,
    });
  }

  searchCourse(
    selectedCenterId: number,
    selectedCourseTypeId: number | undefined,
    selectedAgeMin: number | null,
    selectedAgeMax: number | null,
    selectedChildAge: number | undefined,
    startDateFrom: string,
    startDateTo: string,
    selectedWeekDay: WeekDaysModel | null,
    selectedSemesterId: number | undefined
  ): Promise<CourseModel[]> {
    return backendApi.post('courses/search-courses', {
      selectedCenterId,
      selectedCourseTypeId,
      selectedAgeMin,
      selectedAgeMax,
      selectedChildAge,
      startDateFrom,
      startDateTo,
      selectedWeekDay,
      selectedSemesterId,
    });
  }

  deleteCourseItem(itemKey: number): Promise<CourseShoppingBasketModel> {
    return backendApi.post('courses/delete-course-item', {
      itemKey,
    });
  }

  addCourseToStandbyList(
    selectedUserId: number,
    selectedUserCenterId: number,
    bookingProgramId: number,
    bookingProgramCenterId: number // course center to check standby list is enabled or not
  ): Promise<void> {
    return backendApi.post('courses/add-to-standby-list', {
      selectedUserId,
      selectedUserCenterId,
      bookingProgramId,
      bookingProgramCenterId,
    });
  }
}
