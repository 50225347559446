import { Language } from '@/i18n';
import { createStore } from 'vuex';
import { ConfigModel, SSOHeaderEnum, UserModel } from '../../../common/src';

export interface WebAppStoreState {
  config: ConfigModel | undefined;
  configError: string | undefined;
  user: UserModel | undefined;
  headerType: SSOHeaderEnum;
  availableLocales: Language[];
  currentLocale: Language | undefined;
}

export default createStore<WebAppStoreState>({
  state: {
    config: undefined,
    configError: undefined,
    user: undefined,
    headerType: SSOHeaderEnum.NORMAL,
    availableLocales: [],
    currentLocale: undefined,
  },
  getters: {
    config: (state): ConfigModel | undefined => state.config,
    configError: (state): string | undefined => state.configError,
  },
  mutations: {
    config(state, config: ConfigModel | undefined) {
      state.config = config;
    },
    configError(state, error: string | undefined) {
      state.configError = error;
    },
    user(state, user: UserModel | undefined) {
      state.user = user;
    },
    headerType(state, headerType: SSOHeaderEnum) {
      state.headerType = headerType;
    },
    availableLocales(state, availableLocales: Language[]) {
      state.availableLocales = availableLocales;
    },
    currentLocale(state, language: Language) {
      state.currentLocale = language;
    },
  },
});
