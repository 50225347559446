import * as backendApi from '../shared/backend-api';

export default class ForgotPasswordService {
  constructor() {}

  resetPassword(email: string): Promise<void> {
    return backendApi.post('reset-password/send-email', { email });
  }

  changePasswordWithToken(memberHomeClubId: number, memberId: number, newPassword: string, securityCode: string): Promise<void> {
    return backendApi.post('reset-password/change-password-with-token', { memberHomeClubId, memberId, newPassword, securityCode });
  }
}
