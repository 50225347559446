import { CampModel, CampsShoppingBasketModel } from '../../../common/src';

import * as backendApi from '../shared/backend-api';

export default class CampsService {
  constructor() {}

  getCampsShoppingBasket(): Promise<CampsShoppingBasketModel> {
    return backendApi.get('camps/get-camps-shopping-basket', {});
  }

  searchCamps(
    selectedCenterId: number,
    selectedCourseTypeId: number | undefined,
    selectedAgeMin: number | null,
    selectedAgeMax: number | null,
    selectedChildAge: number | undefined,
    startDateFrom: string,
    startDateTo: string,
    selectedSemesterId: number | undefined,
    sortOrder: string
  ): Promise<CampModel[]> {
    return backendApi.post('camps/search-camps', {
      selectedCenterId,
      selectedCourseTypeId,
      selectedAgeMin,
      selectedAgeMax,
      selectedChildAge,
      startDateFrom,
      startDateTo,
      selectedSemesterId,
      sortOrder,
    });
  }

  getCampDetails(programIds: number[]): Promise<any[]> {
    return backendApi.post('camps/get-camp-details', {
      programIds,
    });
  }

  addCampToShoppingBasket(
    selectedUserId: number,
    selectedUserCenterId: number,
    selectedMemberFirstName: string,
    selectedMemberLastName: string,
    bookingProgramId: number,
    bookingKeys: { id: number; center: number }[]
  ): Promise<CampsShoppingBasketModel> {
    return backendApi.post('camps/add-camp-to-shopping-basket', {
      selectedUserId,
      selectedUserCenterId,
      selectedMemberFirstName,
      selectedMemberLastName,
      bookingProgramId,
      bookingKeys,
    });
  }

  deleteCampItem(selectedUserId: number, selectedUserCenterId: number, bookingProgramId: number): Promise<CampsShoppingBasketModel> {
    return backendApi.post('camps/delete-camp-item', {
      selectedUserId,
      selectedUserCenterId,
      bookingProgramId,
    });
  }
}
