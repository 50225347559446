import { PaymentLinkModel, PaymentMethod } from '../../../common/src';
import * as backendApi from '../shared/backend-api';
const baseUrl = window.location.origin;
export default class LTPSService {
  constructor() {}

  getLTPSPaymentMethods(selectedUserId: number, selectedUserCenterId: number): Promise<PaymentMethod[]> {
    return backendApi.post('ltps/get-ltps-payment-methods', { selectedUserId, selectedUserCenterId });
  }

  createLTPSPaymentAgreement(selectedUserId: number, selectedUserCenterId: number): Promise<void> {
    return backendApi.post('ltps/create-ltps-payment-agreement', { selectedUserId, selectedUserCenterId });
  }

  setAsPaymentAgreement(
    item: PaymentMethod,
    paymentId: string,
    selectedUserId: number,
    selectedUserCenterId: number
  ): Promise<PaymentLinkModel> {
    return backendApi.post('ltps/set-ltps-as-new-payment-agreement', {
      baseUrl,
      item,
      paymentId,
      selectedUserId,
      selectedUserCenterId,
    });
  }
}
